body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: "linear-gradient(185.22deg, #ffffff -4.26%, #f2f2f8 24.47%)";
}

code {
  font-family: source-code-pro;
}

@font-face {
  font-family: "Barlow";
  font-weight: 400;
  src: local("Barlow"), url(../fonts/Barlow-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Barlow";
  font-weight: 500;
  src: local("Barlow"), url(../fonts/Barlow-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Barlow";
  font-weight: 600;
  src: local("Barlow"), url(../fonts/Barlow-SemiBold.ttf) format("truetype");
}

html,
body,
#root,
.App {
  height: 100%;
}
