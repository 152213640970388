.imageContainer {
  height: 320px;
  width: 320px;
  left: 0px;
  top: 0px;
  border-radius: 6px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 16px;
}

.flexColumnContainer {
  display: flex;
  flex-direction: column;
}

.flexRowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spacing {
  margin-top: 40px;
}

.paddingClass {
  padding: 16px;
}

.flexRowContainer h1 {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
}

.flexColumnContainer select {
  padding: 12px 16px;
  margin-left: 32px;

  width: 125px;
  height: 48px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.optionClass {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 196px;
  height: 46px;

  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.parameter {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #211e33;
  margin-bottom: 24px;
}
.attributes {
  height: 52px;
  width: 208px;
  border-radius: 4px;
  padding: 12px 16px;
  cursor: pointer;
}

.attributes p {
  font-family: "Barlow";
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  color: #211e33;
}
.attributes p:first-child {
  font-weight: 400;
  margin: 0;
}

.attributes p:nth-child(2) {
  font-weight: 500;
  margin: 0;
  margin-top: 8px;
}

.maxHeightWidth {
  width: 100%;
  height: 100%;
}

.image-container {
  position: relative;
  margin-right: 80px;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
}
